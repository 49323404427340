const hideProduct = (productCardListElement, variantId) => {
  const productElement = productCardListElement.querySelector(`[data-product-id="${variantId}"]`);
  if (productElement) {
    const showOos = productCardListElement.dataset.showOnlyInStock ? productCardListElement.dataset.showOnlyInStock == 'False' : false;
    if (showOos) {
      // don't hide the product but display OOS message
      productElement.querySelectorAll('.js-cart-instock').forEach((el) => {
        el.classList.add('hide');
      });
      productElement.querySelector('.js-cart-oos')?.classList.remove('hide');
    } else {
      // hide the product
      const productParentElement = productElement.parentElement;
      const productOuter = productParentElement.parentElement.parentElement;
      // remove gallery element
      productParentElement.remove();
    }
  }
};

const updatePrice = (productCardListElement, product, apiResponse) => {
  const productElement = productCardListElement.querySelector(`[data-product-id="${product.pdp_variant_id}"]`);
  const productPriceElement = productElement?.querySelector('.card__price');
  if (productPriceElement) {
    const variantSkus = product.pdp_variant_skus;
    // get 'from' price or variant price
    let newPrice;
    if (variantSkus) {
      // if a product is a combo we need the sum of the prices, otherwise we need the cheapest price
      const arrNums = apiResponse.filter(variant => variantSkus.includes(variant.sku)).map(variant => variant.price);
      newPrice = product.isCombo ? arrNums.reduce((a, b) => a + b, 0) : Math.min(...arrNums);
    } else {
      newPrice = apiResponse.find(variant => variant.sku === product.id).price;
    }
    const productPriceHtml = productPriceElement.innerHTML;
    productPriceElement.innerHTML = productPriceHtml.replace(/(\d+)(?:\.(\d{1,2}))/, newPrice.toFixed(2));
  }
};

const displayProductCardList = (productCardListElement, productListParsed, apiResponse) => {
  const limit = parseInt(productCardListElement.querySelector('.js-pdp-product-card-list')?.dataset.limit) || null;
  let displayCount = 0;
  const oosProducts = apiResponse.filter(variant => !variant.is_in_stock).map(variant => variant.sku);
  productListParsed.forEach((product) => {
    if (limit && displayCount >= limit) {
      hideProduct(productCardListElement, product.pdp_variant_id);
    } else {
      const variantSkus = product.pdp_variant_skus;
      let productOos;
      if (variantSkus) {
        // if a product is a combo only one variant needs to be OOS, otherwise all variants need to be OOS
        productOos = product.isCombo ? variantSkus.some(sku => oosProducts.includes(sku)) : variantSkus.every(sku => oosProducts.includes(sku));
      } else {
        productOos = oosProducts.includes(product.id);
      }
      updatePrice(productCardListElement, product, apiResponse);
      if (productOos && !product.isCollection) {
        // hide the product, unless Collection - collection products should not be hidden if OOS
        hideProduct(productCardListElement, product.pdp_variant_id);
      } else {
        displayCount++;
      }
    }
  });
  return displayCount;
};

export default displayProductCardList;
