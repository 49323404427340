import 'dropzone';

import { postIdentifyEvent, postTrackEvent } from './utils/analytics';

import $ from 'jquery';
import Accordion from 'accordion-js';
import Blazy from 'blazy';
import Glide from '@glidejs/glide';
import Vue from 'vue';
import axios from 'axios';
import displayProductCardList from './product_api_handlers';
import { isMobile } from './utils/deviceHelpers';
import productCarousel from './modules/product_carousel';
import store from './themes/store';
import {sentryConfigs} from './utils/sentry'

sentryConfigs.js()

var filterelements = document.querySelectorAll('.filterelements');
if (filterelements) {
  $(document).on('click', '.filteritems', function(event) {
    const variable = $(this).attr('data-filter');
    $('.filteritems').removeClass('active');
    $(this).addClass('active');
    $('.filtereditems').addClass('hide');
    $('.filtereditems.' + variable).removeClass('hide');
  });
}

const glideTestimonials = document.querySelectorAll('.testimonial__carousel');
if (glideTestimonials) {
  Object.values(glideTestimonials).forEach(item => {
    const glide = new Glide(
      item,
      {
        type: 'slider',
        perView: 1,
        autoplay: 8000,
        bound: true,
        gap: 20,
      },
    );

    const canHover = window.matchMedia('(hover: hover)').matches;
    const blazySelector = canHover ? '.c-lazy, .c-lazy-hover' : '.c-lazy';
    const bLazyCarousel = new Blazy({
      container: '.testimonial__carousel',
      selector: blazySelector,
      offset: 100,
      loadInvisible: true,
      success: function(el) {
        if (canHover) {
          el.closest('.card__image-container')?.classList.add('b-loaded-hover');
        }
      }
    });

    glide.on('move', e => {
      bLazyCarousel.revalidate();
    });
    glide.mount();
  });
}

var glideProductList = document.querySelectorAll('.glide-product-list');
if (glideProductList) {
  var width = window.innerWidth;
  if (width <= 768) {
    const breakpoints = {
      tablet: 1024,
      mobile: 800,
      mobileSmall: 600,
      mobileXSmall: 600
    };

    const canHover = window.matchMedia('(hover: hover)').matches;
    const blazySelector = canHover ? '.c-lazy, .c-lazy-hover' : '.c-lazy';
    const bLazyCarousel = new Blazy({
      container: '.glide-product-list',
      selector: blazySelector,
      offset: 500,
      loadInvisible: true,
      success: function(el) {
        if (canHover) {
          el.closest('.card__image-container')?.classList.add('b-loaded-hover');
        }
      }
    });

    Object.values(glideProductList).forEach(item => {
      const glide = new Glide(
        item,
        {
          type: 'slider',
          perView: 4,
          autoplay: false,
          bound: true,
          gap: 20,
          breakpoints: {
            [breakpoints.tablet]: {
              perView: 3,
            },
            [breakpoints.mobile]: {
              perView: 1,
            }
          }
        },
      );
      glide.on('move', e => {
        let slides = 4;
        if (window.innerWidth < breakpoints.tablet) slides = 3;
        if (window.innerWidth < breakpoints.mobile) slides = 1;
        const length = item.querySelectorAll('.glide__slide').length;
        var calc = ((glide.index) / (length - slides)) * 100;
        const bar = item.parentElement.querySelector('.js-progress-bar');
        bar.style.left = `${calc}%`;
        bLazyCarousel.revalidate();
      });
      glide.mount();
    });
  }
}

var collection = document.querySelectorAll('.collection-glide');
if (collection) {
  const canHover = window.matchMedia('(hover: hover)').matches;
  const blazySelector = canHover ? '.c-lazy, .c-lazy-hover' : '.c-lazy';
  const bLazyCarousel = new Blazy({
    container: '.collection-glide',
    selector: blazySelector,
    offset: 500,
    loadInvisible: true,
    success: function(el) {
      if (canHover) {
        el.closest('.card__image-container')?.classList.add('b-loaded-hover');
      }
    }
  });

  Object.values(glideProductList).forEach(item => {
    const glide = new Glide(
      item,
      {
        type: 'slider',
        perView: 3,
        autoplay: false,
        bound: true,
        gap: 20,
        breakpoints: {
          550: {
            perView: 1,
          },
        }
      },
    );
    glide.on('move', e => {
      let slides = 4;
      if (window.innerWidth < 50) slides = 1;
      const length = item.querySelectorAll('.glide__slide').length;
      var calc = ((glide.index) / (length - slides)) * 100;
      const bar = item.parentElement.querySelector('.js-progress-bar');
      bar.style.left = `${calc}%`;
      bLazyCarousel.revalidate();
    });
    glide.mount();
  });
}

var videoController = document.querySelectorAll('.videoController');
if (videoController) {
  Array.prototype.forEach.call(videoController, (el, index) => new Vue({
    el,
    data: {
      video: undefined,
      showPrompt: false,
      src: '',
      videoId: '',
      height: 780,
      width: 439,
      divId: '',
      actionList: [
        'close',
        'animate',
        'form'
      ],
      formShow: false,
      successMsg: false,
      signupHeader: '',
      signupDescription: '',
      signupInput: '',
      signupBtn: '',
      signupEndpoint: '',
      signupComplete: '',
      textInput: '',
      icon: '',
      action: '',
      title: '',
    },
    mounted() {
      this.height = this.$el.children[0].getAttribute('height');
      this.width = this.$el.children[0].getAttribute('width');
      this.src = this.$el.children[0].getAttribute('src');
      this.divId = this.$el.children[0].getAttribute('id');
      let videoId = this.src.replace('https://www.youtube.com/embed/', '');
      if (videoId.indexOf('?') > -1) {
        videoId = videoId.split('?')[0];
      }
      this.videoId = videoId;
      this.createPlayer();
    },
    methods: {
      analytics: function(status) {
        postTrackEvent('plant-care-video', {
          player: this.videoId,
          status: status
        });
      },
      createPlayer: function() {
        const elem = this;
        if (typeof YT !== 'undefined' && YT && YT.Player) {
          this.video = new YT.Player(this.divId, {
            playerVars: {
              rel: 0,
              enablejsapi: 1,
              color: 'white',
              showinfo: 1,
              autoplay: 0
            },
            events: {
              onReady: elem.analytics('created'),
              onStateChange: elem.onPlayerStateChange
            }
          });
          return this.video;
        } else {
          setTimeout(function () {
            this.createPlayer();
          }.bind(this), 200);
        }
      },
      getState: function(state) {
        switch (state) {
          case 0:
            return 'ended';
          case 1:
            return 'playing';
          case 2:
            return 'paused';
          case 5:
            return 'video cued';
          default:
            return null;
        }
      },
      onPlayerStateChange: function(event) {
        const status = this.getState(event.data);
        if (status !== null) {
          this.analytics(status);
        }
        if (event.data === 2 || event.data === 0) {
          this.showPrompt = true;
        }
      },
      inputData: function (target) {
        this.signupHeader = target.getAttribute('data-header');
        this.signupDescription = target.getAttribute('data-description');
        this.signupInput = target.getAttribute('data-input');
        this.signupBtn = target.getAttribute('data-button');
        this.signupEndpoint = target.getAttribute('data-endpoint');
        this.signupComplete = target.getAttribute('data-complete');
        this.action = target.getAttribute('data-action');
        this.title = target.getAttribute('data-title');
        this.icon = target.getAttribute('data-icon');
      },
      btnClick: function (event, item) {
        this.inputData(event.target);

        if (this.actionList.indexOf(this.action) > -1) {
          switch (this.action) {
            case 'close':
              this.closeAct();
              break;
            case 'animate':
              this.animateAct();
              break;
            case 'form':
              this.formAct();
              break;
            default:
              break;
          }
        }
        this.reportClick();
      },
      reportClick: function () {
        // This is to report to Analytics
        postTrackEvent('Action Bar Prompts', {
          icon: this.icon,
          title: this.title,
          action: this.action
        });
      },
      closeAct: function () {
        this.show = false;
      },
      animateAct: function () {
      },
      formAct: function () {
        this.formShow = true;
      },
      signupClick: function () {
        // Send the email into Analytics
        postTrackEvent(this.signupEndpoint, {
          email: this.textInput
        });
        this.signupBtn = this.signupComplete;
      }
    },
    beforeDestroy () {
      this.analytics('destroy');
    }
  }));
}

var actionPromopts = document.querySelectorAll('.prompt-banner');
if (actionPromopts) {
  Array.prototype.forEach.call(actionPromopts, (el, index) => new Vue({
    el,
    data: {
      actionList: [
        'close',
        'animate',
        'form'
      ],
      show: true,
      formShow: false,
      successMsg: false,
      signupHeader: '',
      signupDescription: '',
      signupInput: '',
      signupBtn: '',
      signupEndpoint: '',
      signupComplete: '',
      textInput: '',
      icon: '',
      action: '',
      title: '',
    },
    methods: {
      inputData: function (target) {
        this.signupHeader = target.getAttribute('data-header');
        this.signupDescription = target.getAttribute('data-description');
        this.signupInput = target.getAttribute('data-input');
        this.signupBtn = target.getAttribute('data-button');
        this.signupEndpoint = target.getAttribute('data-endpoint');
        this.signupComplete = target.getAttribute('data-complete');
        this.action = target.getAttribute('data-action');
        this.title = target.getAttribute('data-title');
        this.icon = target.getAttribute('data-icon');
      },
      btnClick: function (event, item) {
        this.inputData(event.target);

        if (this.actionList.indexOf(this.action) > -1) {
          switch (this.action) {
            case 'close':
              this.closeAct();
              break;
            case 'animate':
              this.animateAct();
              break;
            case 'form':
              this.formAct();
              break;
            default:
              break;
          }
        }
        this.reportClick();
      },
      reportClick: function () {
        // This is to report to Analytics
        postTrackEvent('Action Bar Prompts', {
          icon: this.icon,
          title: this.title,
          action: this.action
        });
      },
      closeAct: function () {
        this.show = false;
      },
      animateAct: function () {
      },
      formAct: function () {
        this.formShow = true;
      },
      signupClick: function () {
        // Send the email into Analytics
        postIdentifyEvent(this.textInput, {
          email: this.textInput,
        });
        postTrackEvent(this.signupEndpoint, {
          email: this.textInput
        });
        this.signupBtn = this.signupComplete;
      }
    }
  }));
}

var signupBanner = document.querySelectorAll('.signupBanner');
if (signupBanner) {
  Array.prototype.forEach.call(
    signupBanner,
    (el, index) =>
      new Vue({
        el,
        name: 'SignupBanner',
        data: {
          emailInput: '',
          msgSent: false,
          signupEndpoints: [],
        },
        computed: {
          localisedBaseUrl() {
            const countryCode = $('#localisePicker')
              .attr('data-country')
              .toLowerCase();
            const languageCode = $('#localisePicker').attr('data-language');

            return `/${countryCode}/${languageCode}`;
          },
        },
        methods: {
          async signUpClick(event) {
            // Get the configured endpoints from the template
            this.signupEndpoints = Array.from(
              this.$refs.endpoints.children,
              child => Number(child.dataset.endpoint)
            );

            // Send the user data into Analytics
            postIdentifyEvent(this.emailInput, {
              email: this.emailInput,
            });
            postTrackEvent('signup-banner-ometria-signup', {
              email: this.emailInput,
              ometriaEndpoints: this.signupEndpoints,
            });

            const csrfmiddlewaretoken = $('input[name="csrfmiddlewaretoken"]').val();

            // Send request to Ometria
            const data = {
              email: this.emailInput,
              location: event.target.getAttribute('data-location'),
              ometria_segments: this.signupEndpoints,
              prismic_signup: true,
              region: $('#localisePicker').attr('data-region'),
            };

            axios
              .post(`${this.localisedBaseUrl}/newsletter/`, data, {
                headers: {
                  'X-CSRFToken': csrfmiddlewaretoken,
                }
              })
              .then(() => {
                // Display success message in signup banner
                this.msgSent = true;
              })
              .catch(error => {
                throw new Error(error);
              });
          },
        },
      })
  );
}

const accordionHeadedTopic = document.querySelectorAll('.headedtopic__wrapper');

if (accordionHeadedTopic?.length > 0) {
  new Accordion('.accordion', {
    openOnInit: accordionHeadedTopic.map((_, index) => index),
    elementClass: 'accordion-item',
    triggerClass: 'accordion-title',
    panelClass: 'accordion-content',
  });
}

var cmsform = document.querySelectorAll('.cmsform');
if (cmsform) {
  Array.prototype.forEach.call(cmsform, (el, index) => new Vue({
    el,
    data: {
      url: '',
      method: '',
      enctype: '',
      formData: {},
      submitButton: undefined,
      errors: {}
    },
    mounted() {
      this.getFieldData();
      const elem = this;
      elem.button = this.$el.querySelector('.button');
      elem.button.addEventListener('click', function() {
        elem.submit();
      });
      this.url = this.$el.getAttribute('action');
      this.method = this.$el.getAttribute('method');
      this.enctype = this.$el.getAttribute('enctype');
    },
    methods: {
      getFieldData () {
        const inputs = Array.from(this.$el.querySelectorAll('input'));
        const select = Array.from(this.$el.querySelectorAll('select'));
        const textarea = Array.from(this.$el.querySelectorAll('textarea'));
        const elem = this;
        if (inputs) {
          inputs.forEach(value => {
            if (!value.name.startsWith('csrf')) {
              elem.formData[value.name] = value.value;
            }
          });
        }
        if (select) {
          select.forEach(value => {
            elem.formData[value.name] = value.value;
          });
        }
        if (textarea) {
          textarea.forEach(value => {
            elem.formData[value.name] = value.value;
          });
        }
      },
      checkErrors() {
        // This goes through the returned elements and adds the error messages to the
        // required fields
        const elem = this;
        const objectKeys = Array.from(Object.keys(elem.errors));
        objectKeys.forEach(function(key) {
          const element = elem.$el.querySelector("[name='" + key + "']");
          const parent = element.parentElement;
          parent.classList.add('error');
          const errorArray = Array.from(elem.errors[key]);
          errorArray.forEach(value => {
            const newElement = document.createElement('span');
            newElement.className = 'error-small';
            newElement.innerText = value.message;
            parent.insertBefore(newElement, element.nextSibling);
          });
        });
      },
      clearErrors() {
        // This will remove all error messages
        const elem = this;
        const smallElements = Array.from(elem.$el.querySelectorAll('.error-small'));
        const errortag = Array.from(elem.$el.querySelectorAll('.error'));
        if (errortag) {
          errortag.forEach(value => {
            value.classList.remove('error');
          });
        }
        if (smallElements) {
          smallElements.forEach(value => {
            value.parentNode.removeChild(value);
          });
        }
      },
      submit() {
        this.clearErrors();
        this.getFieldData();
        const bodyFormData = new FormData();
        const elem = this;
        const objectKeys = Array.from(Object.keys(elem.formData));
        objectKeys.forEach(function(key) {
          bodyFormData.append(key, elem.formData[key]);
        });
        let filecount = 0;
        if (window.fileDataRecord !== undefined) {
          const fileDataArray = Array.from(window.fileDataRecord);
          fileDataArray.forEach(function(fl) {
            bodyFormData.append('form-' + filecount + '-image', fl, fl.name);
            filecount = filecount + 1;
          });
          bodyFormData.append('form-TOTAL_FORMS', filecount);
        }
        axios({
          method: elem.method,
          url: elem.url,
          config:
            {
              headers:
                {
                  'Content-Type': elem.enctype
                }
            },
          data: bodyFormData,
          xsrfCookieName: 'csrftoken',
          xsrfHeaderName: 'X-CSRFToken',
        }).then((response) => {
          /*
            This will handle the POST request if it is successful it will clear form
            and go to the next stage. If there is a problem them it will show the errors.
           */
          const data = response.data;
          if (data.success) {
            this.$el.reset();
            location.reload();
          } else {
            this.clearErrors();
            this.errors = data.errors;
            this.checkErrors();
          }
        }).catch((error) => {
          /*
            This is to handle any 500 or http errors and will show a general error msg
           */
          console.log(error);
          const element = this.$el.firstElementChild;
          const errorElement = document.createElement('div');
          errorElement.innerHTML = '<div class="small-12 column"><p class="error-summary">Whoops. We need some more information to proceed.</p></div>';
          this.$el.insertBefore(errorElement, element);
        });
      }
    }
  }));
}

var dropZoneElement = document.querySelectorAll('.dropzoneElement');
if (dropZoneElement) {
  const url = window.location.href;
  $('.dropzoneElement').dropzone({
    url: url,
    autoProcessQueue: false,
    uploadMultiple: true,
    acceptedFiles: 'image/*',
    paramName: function(n) {
      return 'form-' + n + '-image';
    },
    init: function() {
      document.getElementById('submit-all').addEventListener('click', function(e) {
        // Make sure that the form isn't actually being sent.
        e.preventDefault();
        e.stopPropagation();
      });
      this.on('addedfile', function(file) {
        if (window.fileDataRecord === undefined) {
          window.fileDataRecord = [];
        }
        window.fileDataRecord.push(file);
      });
      this.on('removedfile', function (file) {
        window.fileDataRecord.pop(file);
      });
      //  send all the form data along with the files:
      this.on('sendingmultiple', function(data, xhr, formData) {
        const formArray = $('#form').serializeArray();
        formArray.forEach(function(v, i) {
          if (v.name === 'form-TOTAL_FORMS') {
            formData.append(v.name, data.length);
          } else {
            formData.append(v.name, v.value);
          }
        });
      });
    }
  });
}

var SeoLinks = document.querySelectorAll('.seolinks');
function openLinks(event) {
  const target = event.target;
  const children = target.closest('.seolinks--wrapper').children;
  for (const child of children) {
    child.classList.toggle('active');
  }
  const status = target.closest('.seolinks--mobile').children;
  for (const state of status) {
    state.classList.toggle('active');
  }
};
if (SeoLinks) {
  const mobileLink = Array.from(document.querySelectorAll('.seolinks--mobile'));
  mobileLink.forEach(function(e) {
    e.addEventListener('click', openLinks);
  });
}

const videoEmbedsMobile = document.querySelectorAll('.js-video-embed-mobile');
const videoEmbedsDesktop = document.querySelectorAll('.js-video-embed-desktop');
if (videoEmbedsMobile && videoEmbedsDesktop) {
  // remove un-needed video embed to prevent excess bandwidth
  const unneededVideos = isMobile() ? videoEmbedsDesktop : videoEmbedsMobile;
  unneededVideos.forEach(video => video.parentNode.removeChild(video));
  const videoEmbeds = document.querySelectorAll('.js-video-embed');
  if (videoEmbeds) {
    videoEmbeds.forEach(video => video.classList.remove('video-embed-responsive'));
  }
}

const productCarouselElement = document.querySelector('.js-product-carousel');
if (productCarouselElement) {
  productCarousel({
    trackingLabel: 'Prismic page product carousel',
    breakpointMobile: 768
  });
}

const getItemSkuList = (productListElement, index, productListItem, productIsCombo, productIsCollection) => {
  const itemSkus = [];
  const productSkuListElement = productListItem.querySelector('[data-product-skus-list]');
  if (productSkuListElement) {
    // multi variant product
    const productSkuList = productSkuListElement.getAttribute('data-product-skus-list');
    const productSkuListSplit = productSkuList.substring(1, productSkuList.length - 1).split(',');
    productSkuListSplit.forEach(skuStr => {
      itemSkus.push(skuStr.replace(/'/g, '').trim());
    });
  } else {
    const productDataElement = productListItem.querySelector('[data-collection-product-data]');
    if (productIsCombo && productDataElement) {
      // is a combo product
      const productData = JSON.parse(productDataElement.getAttribute('data-collection-product-data'));
      productData.forEach(product => {
        itemSkus.push(product?.variants[0]?.sku);
      });
    } else if (productIsCollection) {
      // is a collection product
      const productDataElement = productListElement.querySelector('[data-collection-product-data]');
      if (productDataElement) {
        const productData = JSON.parse(productDataElement.getAttribute('data-collection-product-data'));
        itemSkus.push(productData[index]?.variant?.sku);
      }
    } else {
      // single variant product
      const productDataElement = productListItem.querySelector('[data-product-data]');
      if (productDataElement) {
        const productData = JSON.parse(productDataElement.getAttribute('data-product-data'));
        itemSkus.push(productData?.variants[0]?.sku);
      }
    }
  }
  return itemSkus;
};

const getProductCardData = productListElements => {
  // generate a list of SKUs to use for API call for each list
  productListElements.forEach(productListElement => {
    const productListItems = productListElement.querySelectorAll('.js-product-list-item');
    if (productListItems) {
      const skuListArr = [];
      const productList = [];
      productListItems.forEach((productListItem, index) => {
        const productIsCombo = productListItem.getAttribute('data-product-is-combo');
        const productIsCollection = productListItem.getAttribute('data-product-is-collection');
        const itemSkus = getItemSkuList(productListElement, index, productListItem, productIsCombo, productIsCollection);
        skuListArr.push(...itemSkus);
        productList.push({
          // This should match the format of JSON parsed [data-api-list] used on PDPs
          pdp_variant_id: productListItem.getAttribute('data-product-id'),
          pdp_variant_skus: itemSkus.length > 1 ? itemSkus : undefined,
          id: itemSkus[0],
          isCombo: productIsCombo,
          isCollection: productIsCollection,
        });
      });
      if (skuListArr.length) {
        store.dispatch('product/list', {
          query: skuListArr
        }, {root: true}).then((resp) => {
          const displayCount = displayProductCardList(productListElement, productList, resp);
          productListElement.classList.remove('product-cards-initial');
          if (displayCount === 0) {
            productListElement.style.display = 'none';
          }
        })
          .catch((error) => {
            // resort to cached data
            productListElement.classList.remove('product-cards-initial');
          });
      } else {
        productListElement.classList.remove('product-cards-initial');
      }
    }
  });
};

let productDataLoad = false;
const productListElements = document.querySelectorAll('.js-prismic-product-list');

const loadProductCardData = () => {
  if (!productDataLoad) {
    productDataLoad = true;
    // Load data from API for product lists
    window.removeEventListener('scroll', loadProductCardData, { passive: true });
    setTimeout(function() {
      getProductCardData(productListElements);
    }, 501);
  }
};

if (productListElements) {
  if (window.addEventListener) {
    window.addEventListener('scroll', loadProductCardData, { passive: true });
  }
  // check if first element is visible on load
  if (productListElements.length > 0) {
    const rect = productListElements[0].getBoundingClientRect();
    if (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
      loadProductCardData();
    }
  }
};
