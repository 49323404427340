import Blazy from 'blazy';
import Glide from '@glidejs/glide';
import { isInViewport } from '../utils/deviceHelpers';
import {sentryConfigs} from '../utils/sentry'

sentryConfigs.js()
import { postTrackEvent } from '../utils/analytics';

const trackingLabel = 'PDP Product carousel';

const breakpoints = {
  tablet: 1024,
  mobile: 800,
  mobileSmall: 600,
  mobileXSmall: 600
};

if (document.querySelectorAll('.js-visible')) {
  window.onscroll = () => {
    for (const productlist of document.querySelectorAll('.js-visible')) {
      const cards = productlist.querySelectorAll('.card');
      if (isInViewport(productlist) && !productlist.classList.contains('js-visited')) {
        postTrackEvent(trackingLabel, { isVisible: true, slideCount: cards.length, section: productlist.getAttribute('data-id') });
        productlist.classList.add('js-visited');
      }
    }
  };
}
for (const item of document.querySelectorAll('.js-product-carousel-tracking .card__image-container')) {
  const sectionName = item.closest('.js-product-carousel-tracking').getAttribute('data-id');
  item.addEventListener('click', () => {
    postTrackEvent(`${trackingLabel} - item clicked`, { type: 'view product', section: sectionName });
  });
}

for (const item of document.querySelectorAll('.js-product-carousel .button.hollow')) {
  const sectionName = item.closest('.js-product-carousel').getAttribute('data-id');
  item.addEventListener('click', () => {
    postTrackEvent(`${trackingLabel} - item clicked`, { type: 'view options', section: sectionName });
  });
}
if (document.getElementById('pot-url-link')) {
  document.getElementById('pot-url-link').addEventListener('click', event => {
    postTrackEvent('Pot URL link', {});
  });
}

if (document.getElementById('why-not-try-url-link')) {
  document.getElementById('why-not-try-url-link').addEventListener('click', event => {
    if (event.target.closest('.section')) {
      const carousel = event.target.closest('.section').querySelector('.js-product-carousel');
      postTrackEvent('Why not try URL link', { isCarousel: !!carousel });
    }
  });
}

const lazyLoadImages = currentSlide => {
  const canHover = window.matchMedia('(hover: hover)').matches;
  const blazySelector = canHover ? '.c-lazy, .c-lazy-hover' : '.c-lazy';
  const imageLazyload = document.querySelectorAll(blazySelector);
  if (currentSlide > 0) {
    imageLazyload.forEach(img => {
      if (img.dataset.src) {
        const imgSrc = img.dataset.src;
        delete img.dataset.src;
        img.src = imgSrc;
      }
    });
  }
};

const productCarousel = (opts) => {
  Object.values(document.querySelectorAll('.js-product-carousel')).forEach(item => {
    const canHover = window.matchMedia('(hover: hover)').matches;
    const blazySelector = canHover ? '.c-lazy, .c-lazy-hover' : '.c-lazy';
    const bLazyCarousel = new Blazy({
      container: '.js-product-carousel',
      selector: blazySelector,
      offset: 500,
      loadInvisible: true,
      success: function(el) {
        if (canHover) {
          el.closest('.card__image-container')?.classList.add('b-loaded-hover');
        }
      }
    });
    const glide = new Glide(
      item,
      {
        type: 'slider',
        perView: 4,
        autoplay: false,
        bound: true,
        gap: 20,
        breakpoints: {
          [breakpoints.tablet]: {
            perView: 3,
          },
          [breakpoints.mobile]: {
            perView: 1,
          }
        }
      },
    );
    glide.on('move', e => {
      let slides = 4;
      if (window.innerWidth < breakpoints.tablet) slides = 3;
      if (window.innerWidth < breakpoints.mobile) slides = 1;
      const length = item.querySelectorAll('.glide__slide').length;
      const calc = ((glide.index) / (length - slides)) * 100;
      const bar = item.parentElement.querySelector('.js-progress-bar');
      bar.style.left = `${calc}%`;
      bLazyCarousel.revalidate();
      lazyLoadImages(glide.index + 1);
    });
    window.glide=glide;
    glide.mount();
    glide.update();
  });
};

export default productCarousel;
